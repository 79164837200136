<template>
  <b-overlay :show="formShow" rounded="sm" no-fade>
    <div v-show="userDataInfo != null" style="font-size: 1.4em;" class="mt-2 text-center bg-gradient-primary p-2 text-white">
      Hello
      <strong>{{ userDataInfo ? userDataInfo.name : 'N/A' }} {{ userDataInfo ? userDataInfo.surname : 'N/A' }}</strong
      >, welcome to Portal.

      <h3 class="muted mt-2 text-white">{{ dateNow }}</h3>
    </div>
  </b-overlay>
</template>

<script>
import { BCard, BCardHeader, BCardTitle, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody, BLink } from 'bootstrap-vue';
import { kFormatter } from '@core/utils/filter';
import moment from 'moment';
import homeStoreModule from './homeStoreModule';
import store from '@/store';
import { ref, onUnmounted, reactive } from '@vue/composition-api';
import useJwt from '@/auth/jwt/useJwt';
import router from '@/router';

export default {
  components: {
    BLink,
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },

  setup() {
    const HOME_APP_STORE_MODULE_NAME = 'home';
    // Register module
    if (!store.hasModule(HOME_APP_STORE_MODULE_NAME)) store.registerModule(HOME_APP_STORE_MODULE_NAME, homeStoreModule);
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HOME_APP_STORE_MODULE_NAME)) store.unregisterModule(HOME_APP_STORE_MODULE_NAME);
    });
  },

  created() {
    this.checkUser();
  },

  data() {
    const dateNow = moment(new Date()).format('DD/MM/YYYY');

    return {
      dateNow,
      timeLineArray: [],
      formShow: false,
      userDataInfo: null,
    };
  },

  methods: {
    kFormatter,

    checkUser() {
      this.formShow = true;

      this.userId = parseInt(this.userData.id);

      store
        .dispatch('home/fetchUser', { id: this.userId })
        .then((response) => {
          this.userDataInfo = response.data;

          if (response.data.status != 'A') {
            localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName);
            localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName);
            localStorage.removeItem('userData');
            router.push({ name: 'auth-login' });
          } else {
            this.formShow = false;
          }
        })
        .catch((error) => {});
    },
  },
};
</script>

<style></style>
