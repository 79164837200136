import axiosIns from '@/libs/axios';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchSettings(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('settings', { params: queryParams })
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },

    fetchUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axiosIns
          .get(`user/${id}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    getTimeline(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns
          //.get("user", { params: queryParams })
          .get('timeline')
          .then((response) => {
            resolve(response);
          })

          .catch((error) => reject(error));
      });
    },


    ///TASK

    saveProjectTask(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('saveProjectTask', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateProjectTask(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateProjectTask', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateTaskDueDate(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateTaskDueDate', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateTaskAssigneUser(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateTaskAssigneUser', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },



    
    updateTaskPriority(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateTaskPriority', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    updateTaskIsCompleted(ctx, data) {
      return new Promise((resolve, reject) => {
        axiosIns
          .post('updateTaskIsCompleted', data)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

    fetchUsersProject(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axiosIns

          .get('projectUserList', { params: queryParams })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => reject(error));
      });
    },

  },
};
